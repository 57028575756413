.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: 61.5vh;
  justify-content: center;
  background-image: url("/playground_assets/asnjansw.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.home-text {
  font-size: 10vh;
  align-self: center;
  font-style: normal;
  font-family: Circular Std Bold;
  font-weight: 400;
}
.home-container2 {
  gap: 12.5vh;
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text1 {
  font-size: 4vh;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
}
.home-container3 {
  gap: 12.5vh;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-image {
  width: 10.56375vh;
  height: 11.12375vh;
  object-fit: cover;
}
.home-image1 {
  width: 12.875vh;
  height: 12.875vh;
  object-fit: cover;
}
.home-image2 {
  width: 14.36vh;
  height: 13.71875vh;
  object-fit: cover;
}
.home-image3 {
  width: 9.38vh;
  height: 8.95875vh;
  object-fit: cover;
}
.home-image4 {
  width: 10.5vh;
  height: 10.5vh;
  object-fit: cover;
}
.home-image5 {
  width: 18.5vh;
  height: 12.125vh;
  object-fit: cover;
}
.home-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: 52.5vh;
  justify-content: center;
  background-image: url("/playground_assets/sdjcnzsjdvn.svg");
  background-repeat: no-repeat,repeat,repeat;
  background-position: center;
}
.home-text2 {
  font-size: 4vh;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
}
.home-container5 {
  gap: 2.5vh;
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-link {
  font-size: 4vh;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  text-decoration: none;
}
.home-text3 {
  font-size: 4vh;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
}
.home-link1 {
  font-size: 4vh;
  font-style: normal;
  font-family: Circular Std Book;
  font-weight: 400;
  text-decoration: none;
}
